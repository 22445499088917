import React from 'react';
import { connect } from 'react-redux';
import { Page, Cart } from 'components';
import { useSelector } from 'react-redux';
import { CartInitialState } from '../Types/cart';
import { removeFromCart, increment, decrement } from '../redux/actions';

const ProductPage = (props: any) => {
  const products = useSelector((state: any) => state.cart);
  const { removeFromCart, increment, decrement } = props;

  return (
    <Page>
      <section>
        {products.totalQuantity ? (
          <h2>Your Cart</h2>
        ) : (
          <h2>Your cart is empty</h2>
        )}
        {products.cart.map((product: any, key: number) => (
          <Cart
            key={key}
            product={product}
            removeFromCart={removeFromCart}
            increment={increment}
            decrement={decrement}
          />
        ))}
      </section>
      <section>
        <div className="price_details">
          <p className="price_label">Price Details</p>
          <p>
            Total Items:
            {products.totalQuantity ? (
              <span className="quantity">{products.totalQuantity}</span>
            ) : (
              0
            )}
          </p>
          <p>
            {products.totalPrice > 0 ? (
              <h3>Total Price: $ {products.totalPrice}</h3>
            ) : null}
          </p>
        </div>
      </section>
    </Page>
  );
};

const mapStateToProps = (state: CartInitialState) => {
  return {
    cart: state.cart,
    totalPrice: state.totalPrice,
    totalQuantity: state.totalQuantity,
  };
};

const CartPage = connect(mapStateToProps, {
  removeFromCart,
  increment,
  decrement,
})(ProductPage);
export { CartPage };
