import React from 'react';
import { StarRating } from 'components';
import { IProduct } from '../../Types/Product';
import rating from '../ProductListItem/svg/rating.svg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { PlusIcon } from '../Header/svg/plusIcon';
import { NumberSelect } from '../NumberSelect';
import { PackSelect } from './PackSelect';
import { LoveIcon } from '../Header/svg/loveIcon';

interface PropsProductDetails {
  product: IProduct;
  addToBasket: Function;
}

export const ProductDetails = (props: PropsProductDetails) => {
  const { addToBasket } = props;
  const {
    images,
    brandName,
    price,
    productDescription,
    productDetails,
    productName,
  } = props.product;
  return (
    <div className="productdetail">
      <h2 className="productdetail_title">{brandName}</h2>
      <p className="productdetail_name">{productName}</p>
      <div className="productdetail_wrapper">
        <div className="productdetail_image">
          <div className="productdetail_inner">
            <Carousel>
              <div>
                <img src={images[0].url} alt={images[0].title} height="550px" />
              </div>
              <div>
                <img src={images[1].url} alt={images[1].title} height="550px" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="productdetail_content">
          <p className="productdetail_price">
            <span>$</span>
            {price}
            <sup>.99</sup>
          </p>
          <StarRating />
          <p className="productdetail_offers">
            <span className="productdetail_offers_save">Save $10</span>
            <span className="productdetail_offers_save_was">Was $ 30</span>
          </p>
          <img className="productdetail_rating" src={rating} alt="rating" />

          <div className="productdetail_dropdown">
            <button className="productdetail_button">
              <NumberSelect />
            </button>
            <button className="productdetail_button">
              <PackSelect />
            </button>
          </div>
          <button
            className="cta basket"
            onClick={() => addToBasket(props.product)}>
            Add to Basket
            <div className="basket_button">
              <span className="plus svg">
                <PlusIcon />
              </span>
            </div>
          </button>
          <button className="cta wishlist">
            <span className="love">
              <LoveIcon />
            </span>
            Add to Wishlist
          </button>
          <div className="productdetail_moreinfo">
            <p> {productDescription} </p>
          </div>
        </div>
      </div>

      <div className="productdetail_highlights">
        <p className="small_title">Highlights</p>
        <ul>
          {productDetails.map((productdetailsitem: any, key: any) => (
            <li key={key}>
              <span> {productdetailsitem.label}</span>
              {productdetailsitem.value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
