import React from 'react';

const LoveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="30"
      viewBox="0 0 20 18">
      <path
        fill="#D0273A"
        d="M14.943 0c-1.872 0-3.98 1.043-4.955 3.4C9.013 1.042 6.906 0 5.033 0 2.614 0 0 1.738 0 5.562c0 2.82 1.639 5.794 4.916 8.768 2.42 2.202 4.76 3.554 4.877 3.631.04.039.117.039.195.039s.156 0 .195-.039c.117-.077 2.458-1.429 4.877-3.63 3.278-2.975 4.917-5.949 4.917-8.769C19.977 1.738 17.362 0 14.943 0zM9.988 17.112C8.623 16.262.82 11.279.82 5.562c0-3.244 2.185-4.75 4.214-4.75 1.873 0 3.98 1.274 4.565 4.094 0 .193.195.347.39.347s.39-.154.39-.347C10.964 2.086 13.071.81 14.943.81c2.03 0 4.214 1.507 4.214 4.751 0 5.717-7.803 10.7-9.169 11.55z"
      />
    </svg>
  );
};
export { LoveIcon };
