import React from 'react';
// redux
import { connect } from 'react-redux';
// components
import { Page, ProductList } from 'components';

// actions
import * as actions from '../redux/actions/selectProduct';

const ProductPage = (props: any) => {
  const {
    products,
    selectedProduct,
    selectProduct,
    sortBy,
    setSortPriceDesc,
    setSortPriceAsc,
    setSortNameDesc,
    setSortNameAsc,
    addToBasket,
  } = props;

  return (
    <Page>
      <div>
        <ProductList
          products={products}
          selectProduct={selectProduct}
          selectedProduct={selectedProduct}
          sortBy={sortBy}
          setSortPriceDesc={setSortPriceDesc}
          setSortPriceAsc={setSortPriceAsc}
          setSortNameDesc={setSortNameDesc}
          setSortNameAsc={setSortNameAsc}
          addToBasket={addToBasket}
        />
      </div>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  return { ...state };
};

export default connect(mapStateToProps, actions)(ProductPage);
