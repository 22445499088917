import React from "react";

const StoreLocator = () => {
  return (
    <svg
      width="35"
      height="30"
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6111 18.5711V27.7523L16.2872 29.6871C16.8644 30.1043 18.1368 30.1043 18.7141 29.6871L21.3889 27.7523V18.5711C20.1262 18.6836 18.8295 18.7504 17.5 18.7504C16.1705 18.7504 14.8738 18.6836 13.6111 18.5711ZM17.5 0C7.8349 0 0 3.77761 0 8.43767C0 13.0977 7.8349 16.8753 17.5 16.8753C27.1651 16.8753 35 13.0977 35 8.43767C35 3.77761 27.1651 0 17.5 0ZM17.5 4.45321C12.9427 4.45321 9.23611 6.24036 9.23611 8.43767C9.23611 8.82556 8.58229 9.1408 7.77778 9.1408C6.97326 9.1408 6.31944 8.82556 6.31944 8.43767C6.31944 5.46515 11.3361 3.04693 17.5 3.04693C18.3045 3.04693 18.9583 3.36218 18.9583 3.75007C18.9583 4.13797 18.3045 4.45321 17.5 4.45321Z"
        fill="#D0273A"
      />
    </svg>
  );
};
export { StoreLocator };
