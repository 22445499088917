import React from 'react';
import { useSelector } from 'react-redux';
import { Sort } from '../index';

// components
import { ProductListItem } from '../ProductListItem';

const ProductList = (props: any) => {
  const { addToBasket } = props;

  const {
    products: { SortedProducts },
  }: any = useSelector((state: any) => state);

  return (
    <div className="container">
      <div className="filters">
        <Sort />
      </div>
      <div className="product_list">
        {SortedProducts &&
          SortedProducts.map((product: any, key: number) => (
            <ProductListItem
              key={key}
              product={product}
              addToBasket={addToBasket}
            />
          ))}
      </div>
    </div>
  );
};

export { ProductList };
