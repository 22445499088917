import {
  REMOVE_FROM_CART,
  INCREMENT_CART,
  DECREMENT_CART,
} from '../actionTypes';
import { AppAction } from '../../Types/cart';
import { CartInitialState, ShoppingCart } from '../../Types/cart';

const INITIAL_STATE: CartInitialState = {
  cart: [],
  totalPrice: 0,
  totalQuantity: 0,
};

const calculatePrice = (cart: ShoppingCart[]) =>
  cart.reduce((total, item) => total + item.products.price * item.quantity, 0);
const calculateQuantity = (cart: ShoppingCart[]) =>
  cart.reduce((qty, item) => qty + item.quantity, 0);

const cart = (state = INITIAL_STATE, action: AppAction): CartInitialState => {
  const { cart } = state;

  switch (action.type) {
    case 'ADD_TO_BASKET': {
      const newShoppingCart = [...cart];

      let product = newShoppingCart.find(
        (product) => product.products === action.payload
      );
      if (!product) {
        product = {
          products: action.payload,
          quantity: 1,
        };
        newShoppingCart.push(product);
      }

      return {
        ...state,
        cart: newShoppingCart,
        totalPrice: calculatePrice(newShoppingCart),
        totalQuantity: calculateQuantity(newShoppingCart),
      };
    }
    case REMOVE_FROM_CART: {
      let newShoppingCart = [...cart];
      let product = newShoppingCart.find(
        (product) => product.products === action.payload
      );

      if (product) {
        newShoppingCart = newShoppingCart.filter(
          (product) => product.products !== action.payload
        );
      }

      return {
        ...state,
        cart: newShoppingCart,
        totalPrice: calculatePrice(newShoppingCart),
        totalQuantity: calculateQuantity(newShoppingCart),
      };
    }
    case INCREMENT_CART: {
      let newShoppingCart = [...cart];
      let product = newShoppingCart.find(
        (product) => product.products.productId === action.payload.productId
      );
      if (product) {
        product.quantity++;
      }
      return {
        ...state,
        cart: newShoppingCart,
        totalPrice: calculatePrice(newShoppingCart),
        totalQuantity: calculateQuantity(newShoppingCart),
      };
    }
    case DECREMENT_CART: {
      let newShoppingCart = [...cart];
      let product = newShoppingCart.find(
        (product) => product.products.productId === action.payload.productId
      );
      if (product) {
        product.quantity--;

        if (product.quantity === 0) {
          newShoppingCart = newShoppingCart.filter(
            (product) => product.products !== action.payload
          );
        }
      }
      return {
        ...state,
        cart: newShoppingCart,
        totalPrice: calculatePrice(newShoppingCart),
        totalQuantity: calculateQuantity(newShoppingCart),
      };
    }

    default:
      return state;
  }
};

export { cart };
