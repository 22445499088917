import {
  POSTCODE_LOOKUP,
  POSTCODE_RESPONSE,
  POSTCODE_SELECT,
  TOGGLE_NAV,
  REMOVE_FROM_CART,
  INCREMENT_CART,
  DECREMENT_CART,
  SEARCH_PRODUCTS,
  SET_SELECTED_STORE,
  HIDE_POSTCODE,
  RESET_STORE_SELECTION,
  SHOW_POSTCODE_MODAL,
  SORT_PRODUCTS,
} from './actionTypes';
import {
  ProductItem,
  RemoveProduct,
  IncrementQuantity,
  DecrementQuantity,
} from '../Types/cart';
import axios from 'axios';

//cart
export const removeFromCart = (product: ProductItem): RemoveProduct => {
  return {
    type: REMOVE_FROM_CART,
    payload: product,
  };
};
export const increment = (product: ProductItem): IncrementQuantity => {
  return {
    type: INCREMENT_CART,
    payload: product,
  };
};
export const decrement = (product: ProductItem): DecrementQuantity => {
  return {
    type: DECREMENT_CART,
    payload: product,
  };
};

//postcode
interface PropsSearchPostCode {
  searchTerm: string;
  getPostCodeUrl?: string;
}

export const searchPostCode = ({
  searchTerm,
  getPostCodeUrl,
}: PropsSearchPostCode) => {
  return (dispatch: any) => {
    dispatch({
      type: POSTCODE_LOOKUP,
      payload: searchTerm,
    });
    axios({
      method: 'get',
      url: `${getPostCodeUrl}?q=${searchTerm}`,
    }).then((response: any) => {
      dispatch({
        type: POSTCODE_RESPONSE,
        payload: response.data,
      });
    });
  };
};

export const selectPostCode = (locality: object) => {
  return (dispatch: any) => {
    dispatch({
      type: POSTCODE_SELECT,
      payload: locality,
    });
    dispatch({
      type: HIDE_POSTCODE,
    });
  };
};
//store selection
export const SelectedStore = (store: object) => ({
  type: SET_SELECTED_STORE,
  payload: store,
});
export const resetStoreSelection = () => ({
  type: RESET_STORE_SELECTION,
});

//Navagiation bar
export const toggleNav = () => ({
  type: TOGGLE_NAV,
});

//product search
export const productSearch = (searchTerm: string) => ({
  type: SEARCH_PRODUCTS,
  payload: searchTerm,
});

//Toggel post code modal
export const togglePostcodeModal = () => ({
  type: SHOW_POSTCODE_MODAL,
});

export const sortProducts = (product: any, SortBy: string) => (
  dispatch: any
) => {
  let sortedProducts = product.slice();

  switch (SortBy) {
    case 'Latest':
      sortedProducts.sort((a: any, b: any) =>
        a.productId > b.productId ? 1 : -1
      );
      break;

    case 'High':
      sortedProducts.sort((a: any, b: any) => {
        return b.price - a.price;
      });
      break;

    case 'Low':
      sortedProducts.sort((a: any, b: any) => {
        return a.price - b.price;
      });
      break;
  }
  dispatch({
    type: SORT_PRODUCTS,
    payload: {
      SortBy: SortBy,
      items: sortedProducts,
    },
  });
};
