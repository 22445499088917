import React from "react";

const Star = () => {
  return (
    <span className="star">
      <svg
        width="20"
        height="20"
        viewBox="0 0 96 91"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48 0L66.2213 24.9205L95.5528 34.5491L77.4827 59.5795L77.3893 90.4509L48 81L18.6107 90.4509L18.5172 59.5795L0.447174 34.5491L29.7787 24.9205L48 0Z"
          fill="#C4C4C4"
        />
      </svg>
    </span>
  );
};
export { Star };
