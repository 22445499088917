import React from "react";
import { useParams } from "react-router-dom";

import { ProductDetails } from "../ProductDetails";

interface RouteParams {
  id: string | string;
}
const ProductDetailslist = (props: any) => {
  const Params = useParams<RouteParams>();
  const ProductParams = Params.id;
  const { products, addToBasket } = props;

  const product = products.filter(
    (prod: any) => prod.productId === ProductParams
  );
  return (
    <div className="container product_section">
      {product.map((product: any, key: number) => (
        <ProductDetails key={key} product={product} addToBasket={addToBasket} />
      ))}
    </div>
  );
};
export { ProductDetailslist };
