import { SET_SELECTED_STORE, RESET_STORE_SELECTION } from "../actionTypes";
import {
  StoreSelectionTypes,
  StoreSelectionState,
} from "../../Types/PostcodeTypes";

const INITIAL_STATE: StoreSelectionState = {
  selectedStore: null,
};

export const storeSelection = (
  state = INITIAL_STATE,
  action: StoreSelectionTypes
) => {
  let newState;
  switch (action.type) {
    case SET_SELECTED_STORE:
      newState = Object.assign({}, state, {
        selectedStore: action.payload,
      });
      return newState;
    case RESET_STORE_SELECTION:
      newState = Object.assign({}, state, { selectedStore: null });
      return newState;
    default:
      return state;
  }
};
