import React, { createContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from 'Routes';
import { Provider } from 'react-redux';
import { appConfig } from 'config';

import store from 'redux/store';

export const GlobalContext = createContext({
  appConfig,
});

function App() {
  const state = {
    appConfig,
  };

  return (
    <GlobalContext.Provider value={state}>
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    </GlobalContext.Provider>
  );
}

export { App };
