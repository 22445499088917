import React from 'react';
import Select from 'react-select';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'black',
    padding: 10,
  }),
};

const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: 'More', label: 'More' },
];

function NumberSelect(props, onChange) {
  return (
    <div className="productdetail_quantity">
      <Select
        options={options}
        defaultValue={options[0]}
        styles={customStyles}
      />
    </div>
  );
}

export { NumberSelect };
