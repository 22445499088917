import {
  POSTCODE_LOOKUP,
  POSTCODE_RESPONSE,
  POSTCODE_SELECT,
  HIDE_POSTCODE,
  SHOW_POSTCODE_MODAL,
} from '../actionTypes';

import { PostCodeStateTypes, PostCodeActionTypes } from 'Types/PostcodeTypes';

const INITIAL_STATE: PostCodeStateTypes = {
  isPostcodeModalOpen: false,
  searchTerm: '',
  results: [],
  selectedPostCode: null,
};

const postCode = (state = INITIAL_STATE, action: PostCodeActionTypes) => {
  let newState;
  switch (action.type) {
    case POSTCODE_LOOKUP:
      newState = Object.assign({}, state, { searchTerm: action.payload });
      return newState;
    case POSTCODE_RESPONSE:
      const {
        payload: {
          localities: { locality },
        },
      } = action;
      let results: any[] = [];
      if (locality) {
        if (Array.isArray(locality)) {
          results = locality;
        } else {
          results = [locality];
        }
      }
      newState = Object.assign({}, state, { results });
      return newState;
    case POSTCODE_SELECT:
      newState = Object.assign({}, state, { selectedPostCode: action.payload });
      return newState;
    case HIDE_POSTCODE:
      newState = Object.assign({}, state, { results: [] });
      return newState;
    case SHOW_POSTCODE_MODAL:
      newState = Object.assign({}, state, {
        isPostcodeModalOpen: !state.isPostcodeModalOpen,
      });
      return newState;
    default:
      return state;
  }
};

export { postCode };
