import React from "react";
import { searchPostCode, selectPostCode } from "redux/actions";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { postCodeConfig } from "config";
import { debounceEvents } from "utils/debounce";

const PostCodeSearch = () => {
  const dispatch = useDispatch();

  const { getPostCodeUrl } = postCodeConfig;

  const {
    postCode: { results, selectedPostCode },
    storeSelection: { selectedStore },
  }: any = useSelector((state) => state);

  const handleForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    if (searchTerm && searchTerm.length >= 3) {
      dispatch(searchPostCode({ searchTerm, getPostCodeUrl }));
    }
  };

  const setSelectedLocality = (locality: object) => (
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.preventDefault();
    dispatch(selectPostCode(locality));
  };

  return (
    <>
      <div
        className={classNames("postcode_searchbar", {
          hide: selectedStore != null,
        })}
      >
        <form onSubmit={handleForm}>
          <input
            type="text"
            placeholder="Enter Postcode or Subhub "
            onChange={debounceEvents(handleChange, 500)}
          />
        </form>
      </div>

      <ul className="postcode_list">
        {results.map((locality: any, key: number) => (
          <li
            key={`locality-${key}`}
            className={classNames("select-post-code", {
              active:
                selectedPostCode &&
                selectedPostCode.postcode === locality.postcode,
            })}
            onClick={setSelectedLocality(locality)}
          >
            <strong>{locality.postcode} </strong>
            {locality.location} <em>{locality.state}</em>
          </li>
        ))}
      </ul>
    </>
  );
};

export { PostCodeSearch };
