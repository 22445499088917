import { ProductItem, AddProduct } from "../../Types/cart";

export const addToBasket = (product: ProductItem): AddProduct => {
  return {
    type: "ADD_TO_BASKET",
    payload: product,
  };
};

export const setSortPriceDesc = () => {
  return { type: "PRICE_DESC" };
};
export const setSortPriceAsc = () => {
  return { type: "PRICE_ASC" };
};
export const setSortNameDesc = () => {
  return { type: "NAME_DESC" };
};
export const setSortNameAsc = () => {
  return { type: "NAME_ASC" };
};
export const setSortCategory = () => {
  return { type: "NAME_DESC" };
};
export const setSortBrand = () => {
  return { type: "NAME_ASC" };
};
