import React, { FC, MouseEvent } from 'react';

import close from './svg/close.svg';

interface PropsCart {
  increment: (event: MouseEvent) => void;
  decrement: (event: MouseEvent) => void;
  removeFromCart: (event: MouseEvent) => void;
  product: any;
}
const Cart: FC<PropsCart> = (props) => {
  const { product, removeFromCart, increment, decrement } = props;
  const { products, quantity } = product;
  const { images, brandName, productName, price } = products;

  return (
    <div className="product_cart">
      <div className="product_image">
        <img src={images[0].url} alt={images[0].title} height="100px" />
      </div>
      <p className="product_title">
        {brandName} <span className="product_name">{productName}</span>
      </p>
      <div className="product_add-remove-actions">
        <button
          onClick={() => {
            decrement(products);
          }}>
          -
        </button>
        <span>{quantity}</span>
        <button
          onClick={() => {
            increment(products);
          }}>
          +
        </button>
      </div>
      <p className="product_price">
        <span>$</span>
        {price * quantity}
        <sup>.99</sup>
      </p>
      <button className="close_btn" onClick={() => removeFromCart(products)}>
        <img src={close} alt="close" className="close" />
      </button>
    </div>
  );
};

export { Cart };
