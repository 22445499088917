import React from 'react';
import { Star } from './star';
const StarRating = () => {
  return (
    <div className="star_rating">
      {[...Array(5)].map(() => {
        return <Star />;
      })}
    </div>
  );
};

export { StarRating };
