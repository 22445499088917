import React from "react";
import rating from "./svg/rating.svg";
import { IProduct } from "../../Types/Product";
import { Link } from "react-router-dom";

interface PropsProductListItem {
  product: IProduct;
  addToBasket: Function;
}
export const ProductListItem = (props: PropsProductListItem) => {
  const { product, addToBasket } = props;
  const {
    productId,
    images,
    brandName,
    price,
    productName,
    saleRibbon,
  } = product;

  return (
    <div className="product">
      <Link to={`/product-details/${productId}`}>
        <div className="product_img_container">
          {images && images.length && (
            <img src={images[0].url} alt={images[0].title} height="200px" />
          )}
          <span className="product_ribbon">{saleRibbon}</span>
        </div>

        <div className="product_details">
          <p className="product_brand">{brandName}</p>
          <p className="product_name">{productName}</p>
          <img src={rating} alt="rating" />
          <p className="product_price">
            <span>$</span>
            {price}
            <sup>.99</sup>
          </p>
          <p className="offers">
            <span className="offers_save">Save $10</span>
            <span className="offers_save_was">Was $ 30</span>
          </p>
        </div>
      </Link>
      <button className="cta_btn" onClick={() => addToBasket(product)}>
        Add to Basket
      </button>
    </div>
  );
};
