import React from "react";
import { useSelector } from "react-redux";

const StoreProducts = () => {
  const {
    storeSelection: { selectedStore },
  }: any = useSelector((state) => state);
  return <div>{selectedStore ? <div>{selectedStore.title}</div> : null}</div>;
};

export { StoreProducts };
