export const ADD_TO_CART = 'ADD_TO_CART';
export const POSTCODE_LOOKUP = 'POSTCODE_LOOKUP';
export const POSTCODE_RESPONSE = 'POSTCODE_RESPONSE';
export const POSTCODE_SELECT = 'POSTCODE_SELECT';
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const INCREMENT_CART = 'INCREMENT_CART';
export const DECREMENT_CART = 'DECREMENT_CART';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SET_SELECTED_STORE = 'SET_SELECTED_STORE';
export const HIDE_POSTCODE = 'HIDE_POSTCODE';
export const RESET_STORE_SELECTION = 'RESET_STORE_SELECTION';
export const SHOW_POSTCODE_MODAL = 'SHOW_POSTCODE_MODAL';
export const SORT_PRODUCTS = 'SORT_PRODUCTS';
//export const FILTER_PRODUCTS_BY_SIZE = 'FILTER_PRODUCTS_BY_SIZE';
