import React from 'react';
import Select from 'react-select';

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'black',
    padding: 10,
  }),
};

const packoptions = [
  { value: 'bottle(s)', label: 'bottle(s)' },
  { value: 'case(s)', label: 'case(s)' },
];

function PackSelect(props) {
  return (
    <div className="productdetail_bottle">
      <Select
        className="select"
        options={packoptions}
        defaultValue={packoptions[0]}
        styles={customStyles}
      />
    </div>
  );
}

export { PackSelect };
