import React from "react";
import { Page, StoreProducts } from "components";
import { StoreSelection } from "@postcode/components";

const Home = () => (
  <>
    <Page>
      <StoreSelection />
      <StoreProducts />
    </Page>
  </>
);

export { Home };
