import { SEARCH_PRODUCTS } from '../actionTypes';

const INITIAL_STATE: any = {
  searchTerm: '',
};

const searchProducts = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SEARCH_PRODUCTS:
      return Object.assign({}, state, { searchTerm: action.payload });

    default:
      return state;
  }
};

export { searchProducts };
