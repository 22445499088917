import { TOGGLE_NAV } from '../actionTypes';

const INITIAL_NAV: any = { isNavOpen: false };

const navigation = (state = INITIAL_NAV, action: any) => {
  switch (action.type) {
    case TOGGLE_NAV:
      return Object.assign({}, state, { isNavOpen: !state.isNavOpen });
    default:
      return state;
  }
};

export { navigation };
