import { SORT_PRODUCTS } from "redux/actionTypes";

const INITIAL_STATE = {
  SortBy: "",
  SortedProducts: [],
};

export const sortBy = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SORT_PRODUCTS:
      return {
        ...state,
        SortBy: action.payload.sortby,
        SortedProducts: action.payload.items,
      };

    default:
      return state;
  }
};
