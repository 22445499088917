import React from "react";

import { ProductDetailslist, Page } from "components";
import { useSelector } from "react-redux";

import { addToBasket } from "redux/actions/selectProduct";

const ProductDetails = (props: any) => {
  const { products }: any = useSelector((state) => state);
  return (
    <Page>
      <ProductDetailslist products={products} addToBasket={addToBasket} />
    </Page>
  );
};

export { ProductDetails };
