/* A debounce function that triggers a callback after a set wait period
 * @param callback
 * @param wait
 */
export const debounceEvents = (callback: Function, wait: number) => {
  let timeout: any;
  return (event: any) => {
    event.persist();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(event);
    }, wait);
  };
};
