import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  Home,
  Products,
  CartPage,
  Admin,
  ProductPage,
  ProductDetails,
} from 'Pages';

const routesObj = {
  home: {
    path: '/',
    title: 'Home',
    component: <Home />,
  },
  cart: {
    path: '/cart',
    title: 'Cart',
    component: <CartPage />,
  },
  products: {
    path: '/products',
    title: 'Products',
    component: <Products />,
  },
  admin: {
    path: '/admin',
    title: 'Admin',
    component: <Admin />,
  },
  ProductPage: {
    path: '/product-page',
    title: 'Product Page',
    component: <ProductPage />,
  },
  ProductDetails: {
    path: '/product-details/:id',
    title: 'Product Detail',
    component: <ProductDetails />,
  },
};

const routes = [
  routesObj.cart,
  routesObj.products,
  routesObj.admin,
  routesObj.ProductPage,
  routesObj.ProductDetails,
];

// ensure home is the last item in the list
routes.push(routesObj.home);

const mainNavroutes = [
  routesObj.home,
  routesObj.products,
  routesObj.cart,
  routesObj.admin,
  routesObj.ProductPage,
];

const Routes = () => (
  <Switch>
    {routes.map((route, key) => (
      <Route key={`route${key}`} path={route.path}>
        {route.component}
      </Route>
    ))}
  </Switch>
);

export { Routes, mainNavroutes };
