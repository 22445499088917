import data from "../data/products.json";

import { SORT_PRODUCTS } from "../actionTypes";
const INITIAL_PRODUCTS = {
  Product: data,
  SortedProducts: data,
  SortBy: null,
};

export const products = (state = INITIAL_PRODUCTS, action: any) => {
  switch (action.type) {
    case SORT_PRODUCTS:
      return Object.assign({}, state, {
        SortBy: action.payload.sortby,
        SortedProducts: action.payload.items,
      });
  }
  return state;
};
