import React from 'react';

import { Link } from 'react-router-dom';

import { mainNavroutes } from './Routes';
import { toggleNav } from 'redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

export default () => {
  const dispatch = useDispatch();
  const { isNavOpen } = useSelector((state: any) => {
    return state.navigation;
  });
  return (
    <nav className={classNames('navigation_items', { active: isNavOpen })}>
      <button
        className="navigation_menu"
        onClick={() => {
          dispatch(toggleNav());
        }}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <div
        className="navigation_side-bar"
        onClick={() => {
          dispatch(toggleNav());
        }}>
        <ul className="navigation_list">
          {mainNavroutes.map((route, key) => (
            <li key={`link${key}`}>
              <Link to={route.path}>{route.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
