import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { GlobalContext } from 'App';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav } from 'Routes';
import { CartInitialState } from '../../Types/cart';

import { productSearch } from 'redux/actions';
import logo from './svg/logo.svg';
import { CartIcon } from './svg/cartIcon';
import { LoveIcon } from './svg/loveIcon';
import { togglePostcodeModal } from '../../redux/actions';
import { StoreLocator } from './svg/Storelocator';

const HeaderSection = (props: any) => {
  const {
    appConfig: { title },
  } = useContext(GlobalContext);
  const products = useSelector((state: any) => state.cart);

  const dispatch = useDispatch();

  const handleForm = (e: any) => {
    e.preventDefault();
    const searchTerm = e.target.querySelector('input').value;
    dispatch(productSearch(searchTerm));
  };

  const toggleOverlay = () => {
    dispatch(togglePostcodeModal());
  };

  return (
    <header className="header">
      <div className="container">
        <div className="navigation">
          <div className="navigation_wrapper">
            <Nav />
            <Link to="/">
              <img src={logo} className="logo" alt={title} />
            </Link>
          </div>
          <div className="navigation_buttons">
            <div className="header_icons">
              <form onSubmit={handleForm}>
                <input type="text" className="search" placeholder="search" />
              </form>
            </div>
            <div className="header_icons">
              <button onClick={toggleOverlay} className="store-pin">
                <StoreLocator />
              </button>
            </div>
            <div className="header_icons">
              <button className="love">
                <LoveIcon />
              </button>
            </div>
            <div className="header_icons">
              <Link to="/cart">
                <button className="cart">
                  <CartIcon />
                  {products.totalQuantity ? (
                    <span className="quantity">{products.totalQuantity}</span>
                  ) : null}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state: CartInitialState) => {
  return { totalQuantity: state.totalQuantity };
};
const Header = connect(mapStateToProps)(HeaderSection);
export { Header };
