import React, { FC, MouseEvent } from 'react';

interface PropsOverlay {
  header: string;
  toggleOverlay: (event: MouseEvent) => void;
}

const Overlay: FC<PropsOverlay> = (props) => (
  <div className="overlay" onClick={props.toggleOverlay}>
    <div
      className="overlay-container"
      onClick={(e) => {
        e.stopPropagation();
      }}>
      {props.header && (
        <div className="overlay-header">
          <h2>{props.header}</h2>
        </div>
      )}

      <div className="overlay-body">{props.children}</div>
    </div>
  </div>
);

export { Overlay };
