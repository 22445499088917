import React from 'react';

import { Header, Footer } from 'components';

const Page = ({ children }: any) => (
  <>
    <Header />
    <div className="container">{children}</div>

    <Footer />
  </>
);

export { Page };
