import React, { useEffect, useState, FC, MouseEvent } from "react";
import stores from "./stores.json";
import { useSelector, useDispatch } from "react-redux";
import { SelectedStore, resetStoreSelection } from "redux/actions";
import classNames from "classnames";
interface PropsFilteredStore {
  toggleOverlay: (event?: MouseEvent) => void;
}

const FilteredStore: FC<PropsFilteredStore> = (props) => {
  const dispatch = useDispatch();

  const {
    postCode: { selectedPostCode, searchTerm },
    storeSelection: { selectedStore },
  }: any = useSelector((state) => state);

  const [filteredStores, setFilteredStores] = useState<Array<object>>([]);
  const [unavailableStore, setunavailable] = useState<string>("");

  useEffect(() => {
    if (selectedPostCode) {
      const filteredList = stores.filter(
        (store) => store.address.postcode === selectedPostCode.postcode
      );
      setFilteredStores(filteredList);
      setunavailable("no store availabe at your locations");
    }
  }, [selectedPostCode]);

  useEffect(() => {
    setFilteredStores([]);
    setunavailable("");
  }, [searchTerm]);

  const setSelectedStore = (store: object) => {
    dispatch(SelectedStore(store));
    setFilteredStores([]);
    props.toggleOverlay();
  };

  const changeStoreSelection = () => {
    dispatch(resetStoreSelection());
    setFilteredStores([]);
  };
  return (
    <div>
      <div className="filtered_store">
        {filteredStores && (
          <ul>
            {filteredStores.map((store: any, key: number) => {
              return (
                <div>
                  <p>Near ByStore</p>
                  <li key={`store-${key}`}>
                    {store.title}
                    <div className="filtered_store_options">
                      <button className="filtered_store_options_left">
                        Store Details
                      </button>
                      <button
                        className="filtered_store_options_right"
                        onClick={() => setSelectedStore(store)}
                      >
                        Set As Mystore
                      </button>
                    </div>
                  </li>
                </div>
              );
            })}
          </ul>
        )}
        <div
          className={classNames("unavailable_store_location", {
            active: filteredStores.length,
          })}
        >
          {unavailableStore}
        </div>
      </div>

      <div className="selected_store">
        {selectedStore ? (
          <>
            <span>Your Store is</span>
            <div className="selected_store_conatiner">
              <div className="selected_store_detail">
                <h4>{selectedStore.title}</h4>
              </div>
              <button
                className="selected_store_change"
                onClick={() => changeStoreSelection()}
              >
                Change Store
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export { FilteredStore };
