import React from 'react';
import { Page } from 'components';
import { connect } from 'react-redux';

const products = [
  { title: 'Sauvignon Blanc', brand: 'Giesen' },
  { title: 'Irish Whiskey 700mL', brand: 'Jameson' },
  { title: 'Reserve Shiraz', brand: "Jacob's Creek" },
  { title: 'Black Label', brand: 'Johny Walker' },
  { title: 'Estate Shiraz 2013', brand: 'Taylors' },
];

const UnConnectedProducts = (props: any) => {
  return (
    <Page>
      <section>
        <h1>Products</h1>
        <ul>
          {products.map((product, key) => (
            <li key={`product-${key}`}>
              <div>
                <strong>{product.brand}</strong> {product.title}
              </div>
              <div></div>
            </li>
          ))}
        </ul>
        <h2>Cart</h2>
      </section>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  const { cart } = state;
  return { cart };
};

const Products = connect(mapStateToProps)(UnConnectedProducts);
export { Products };
