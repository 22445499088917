import React from 'react';

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer_list">
        <div className="footer_items">
          <h4>About RedBasket</h4>
          <ul>
            <li>About Us</li>
            <li>Contact Us</li>
            <li>Mobile Version</li>
            <li>Mobile App</li>
            <li>Site Map</li>
          </ul>
        </div>
        <div className="footer_items">
          <h4>Info</h4>
          <ul>
            <li>T & C</li>
            <li>Privacy Policy</li>
            <li>FAQ</li>
            <li>Blog</li>
          </ul>
        </div>
        <div className="footer_items">
          <ul>
            <h4>Info</h4>
            <li>T & C</li>
            <li>Privacy Policy</li>
            <li>FAQ</li>
            <li>Blog</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export { Footer };
