import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortProducts } from 'redux/actions';
import Select from 'react-select';

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,

    color: state.isSelected ? '#ffffff' : '#D0273A',
    backgroundColor: state.isSelected ? '#D0273A' : '#ffffff',
    padding: 10,
  }),
};

const Sort = () => {
  const dispatch = useDispatch();

  const {
    products: { SortedProducts, SortBy },
  }: any = useSelector((state: any) => state);

  const handleSortChange = (SortBy: any) => {
    dispatch(sortProducts(SortedProducts, SortBy.value));
  };

  const options = [
    { value: 'Latest', label: 'Latest' },
    { value: 'High', label: 'Highest' },
    { value: 'Low', label: 'Lowest' },
  ];
  return (
    <div className="sort_buttons">
      <Select
        value={SortBy}
        options={options}
        onChange={handleSortChange}
        styles={customStyles}
        isSearchable={false}
        placeholder="Sort By"
      />
    </div>
  );
};

export { Sort };
