import { combineReducers } from "redux";

import { postCode } from "./postCode";
import { products } from "./products";
import { navigation } from "./navigation";
import { sortBy } from "./sortBy";
import { cart } from "./cart";
import { storeSelection } from "./selectedStore";
import { searchProducts } from "./searchProducts";

export default combineReducers({
  products,
  postCode,
  navigation,
  sortBy,
  cart,
  storeSelection,
  searchProducts,
});
