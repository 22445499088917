import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as firebase from 'firebase';
import { Page } from 'components';
import { firebaseConfig } from 'config';

var app = firebase.initializeApp(firebaseConfig);

const Admin = () => {
  const db = firebase.firestore(app);

  const [products, setProducts]: any = useState([]);

  const INITIAL_INPUTS = { title: '', brand: '' };

  const [inputs, setInputs] = useState(INITIAL_INPUTS);

  const getProducts = () => {
    db.collection('products')
      .get()
      .then((querySnapshot) => {
        const userArray: any = [];
        querySnapshot.forEach((doc) => {
          userArray.push(doc.data());
        });
        setProducts(userArray);
      })
      .catch((error) => {
        console.error('Error reading collection: ', error);
      });
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNew = (product: any) => {
    db.collection('products')
      .doc(uuidv4())
      .set(product)
      .then(getProducts)
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  };

  const handleChange = (event: any) => {
    console.log(event.target);
    const newtInputs: any = Object.assign({}, inputs);
    newtInputs[event.target.id] = event.target.value;
    console.log(newtInputs);
    setInputs(newtInputs);
  };

  const handleForm = (event: any) => {
    event.preventDefault();
    createNew(inputs);
  };

  return (
    <Page>
      {products &&
        products.map((product: any, key: number) => (
          <div className="product" key={key}>
            <strong>{product.brand} </strong>
            {product.title}
          </div>
        ))}
      <form onSubmit={handleForm}>
        <div>
          <label>
            Title:
            <input
              type="text"
              name="title"
              id="title"
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Brand:
            <input
              type="text"
              name="brand"
              id="brand"
              onChange={handleChange}
            />
          </label>
        </div>

        <button type="submit">Add New</button>
      </form>
    </Page>
  );
};

export { Admin };
