const appConfig = {
  title: 'Redbasket',
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

const postCodeConfig = { getPostCodeUrl: process.env.REACT_APP_getPostCodeUrl };

export { appConfig, firebaseConfig, postCodeConfig };
