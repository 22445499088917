import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Overlay } from "components";
import { PostCodeSearch, FilteredStore } from "../index";
import { togglePostcodeModal } from "redux/actions";

const StoreSelection = () => {
  const dispatch = useDispatch();

  const {
    postCode: { isPostcodeModalOpen },
  }: any = useSelector((state) => state);

  const toggleOverlay = () => {
    dispatch(togglePostcodeModal());
  };

  return (
    <>
      {isPostcodeModalOpen && (
        <Modal>
          <Overlay header="Select Store" toggleOverlay={toggleOverlay}>
            <PostCodeSearch />
            <FilteredStore toggleOverlay={toggleOverlay} />
          </Overlay>
        </Modal>
      )}
    </>
  );
};

export { StoreSelection };
